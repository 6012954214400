import $ from 'jquery';
import config from './location.config';
import Map from './googleMaps';
import anime from 'animejs';

let map = new Map('map');

export default class MapPosition {
	constructor() {
		this.data = config.mapData;
		this.$infoStock = $('#infoStock');
		this.$stateListContainer = this.$infoStock.find('#state-list');
		this.$stockListContainer = this.$infoStock.find('#stock-list');
		this.$stockLocationContainer = this.$infoStock.find('#stock-location');
		this.$layyerStock = this.$infoStock.find('[data-layyer="stock"]');
		this.$layyerCard = this.$infoStock.find('[data-layyer="card"]');

		this._state = '';
		this._stock = '';
		this.stockLocationData = '';
	}

	init() {
		// this.renderList(this.$stateListContainer, this.data);

		this.$stateListContainer.click(e => this.moveToStock(e));
		this.$stockListContainer.click(e => this.moveToCard(e));

		const firstStock = this.data[0].stock[0];

		this.renderCard(this.$stockLocationContainer, firstStock);
		$("#map").stop().animate({opacity: 0}, 50);

		map.init(firstStock.mapLocation, ' ');

		$('.location__svg').css({
			'visibility': 'hidden',
			'opacity': 0
		});
		$('.location #map').show().css({
			'z-index': '1',
		});
		// this.$layyerStock.click((e) => {
		// 	this.backTo(
		// 		e, this.$stockListContainer, this.$stateListContainer, this.data,
		// 		() => {
    //             	$('.location__svg svg path').css('fill', '#ECEEF3');
    //         	}
		// 	);
		// });

		// this.$layyerCard.click((e) => {
		// 	this.backTo(
		// 		e, this.$stockLocationContainer, this.$stockListContainer, this.stockLocationData,
		// 		()=> {
		// 			$('.location__svg').css({
		// 				'visibility': 'visible',
		// 				'opacity': 1
		// 			});
		//
		// 			$('#map').hide().css({
		// 				'z-index': '-1',
		// 			});
		// 		}
		// 	);
		// });
	};

	backTo(e, $containerToClear, $containerToRender, dataRender, callback) {
		const $layyer = this.getCurrentLayyer(e);

		if (!$layyer) return;

		const $prevElSiblingList = $layyer.prev().find('li');

		$prevElSiblingList.removeClass('active');
		$containerToClear.children().remove();
		this.renderList($containerToRender, dataRender);
		return callback();
	}

	moveToStock(e) {
		const $clickResult = this.getActiveItem(e);

		if ($clickResult !== undefined && $clickResult !== this._state) {
			this._state = $clickResult;

			const $thisLayyer = this._state.closest('[data-layyer]');
			const $nextLayyer = $thisLayyer.next();
			const objectStockData = this.getObjPropsById(this.data, this._state.attr('data-state'));


			$nextLayyer.addClass('active');
			this._state.parent().children().remove();
			this.stockLocationData = objectStockData.stock;
			this.renderList(this.$stockListContainer, this.stockLocationData);

			$(`.location__svg #${objectStockData.id}`).css('fill', '#fbc02d');
		}
	};

	moveToCard(e) {
		const $clickResult = this.getActiveItem(e);

		if ($clickResult !== undefined && $clickResult !== this._stock) {
			this._stock = $clickResult;

			const $thisLayyer = this._stock.closest('[data-layyer]');
			const $nextLayyer = $thisLayyer.next();
			const objectCardData = this.getObjPropsByName(this.stockLocationData, this._stock.attr('data-stock'));

			$nextLayyer.addClass('active');
			this._stock.parent().children().remove();
			this.renderCard(this.$stockLocationContainer, objectCardData);

			$("#map").stop().animate({opacity: 0}, 50);

			map.init(objectCardData.mapLocation, objectCardData.name);

			$('.location__svg').css({
				'visibility': 'hidden',
				'opacity': 0
			});
			$('.location #map').show().css({
				'z-index': '1',
			});
		}
	};

	getActiveItem(e) {
		// e.preventDefault();
		// e.stopPropagation();

		const $target = $(e.target);
		const $item = $target.closest('li');

		if (!$item.length) return;

		const $itemList = $item.parent().find('li');

		$itemList.removeClass('active');
		$item.addClass('active');

		return $item;
	};

	getCurrentLayyer(e) {
		// e.preventDefault();
		// e.stopPropagation();

		const $target = $(e.target);
		const $item = $target.closest('.map-info__title');

		if (!$item.length) return;

		const $layyer = $item.closest('[data-layyer]');

		$layyer.removeClass('active');

		return $layyer;
	}

	getObjPropsById(data, activeValue) {
		for (let props in this.data) {
			if (data[props].id === activeValue) {
				return data[props];
			}
		}
	};

	getObjPropsByName(data, activeValue) {
		for (let props in this.data) {
			if (data[props].name === activeValue) {
				return data[props];
			}
		}
	};

	renderList($listContainer, data, animate = true) {
		const listTemplate = [];
		const listContainerID = $listContainer.attr('id');
		const typeList = listContainerID.split('-')[0];

		data.forEach(item => listTemplate.push(this.makeList(item, typeList)));

		const template = listTemplate.toString().replace(/,/g, '');
		$listContainer.append(template); // insertAdjacappendentHTML

		if (animate) {
			this.animate(`#${listContainerID} .${listContainerID}__item`);
		}
	};

	renderCard($cardContainer, data, animate = true) {
		const cardContainerID = $cardContainer.attr('id');
		const template = this.makeCard(data);

		$cardContainer.append(template); //insertAdjacentHTML

		if (animate) {
			this.animate(`#${cardContainerID} > *`);
		}
	};

	animate(selector) {
		anime({
			targets: selector,
			duration: function (t, i) {
				return 1000 + i * 250;
			},
			easing: 'easeOutExpo',
			delay: function (t, i) {
				return i * 20;
			},
			opacity: {
				value: [0, 1],
				duration: function (t, i) {
					return 250 + i * 50;
				},
				easing: 'linear'
			},
			translateY: [50, 0]
		});
	};

	makeList(item, typeList) {
		if (typeList === 'state') {
			return `<li class="state-list__item" data-state="${item.id}">${item.name}</li>`
		} else if (typeList === 'stock') {
			return `<li class="stock-list__item" data-stock="${item.name}">${item.name}</li>`
		}
	};

	makeCard(data) {
		function getStrList(data, template) {
			if (typeof (data) === "object") {
				let strList = [];

				data.forEach(item => strList.push(template(item)));

				return strList.toString().replace(/,/g, '');
			} else {
				return template(data);
			}
		}

		function makeEmailTemplate(item) {
			return `<a class="stock-location__email" href="mailto:${item}">${item}</a>`
		}

		function makePhoneTemplate(item) {
			return `<div class="stock-location__phone">${item}</div>`
		}

		return `
			<div class="stock-location__name">${data.name}</div>
			<div class="stock-location__address">${data.address}</div>
			<div class="stock-location__manager">
				<span class="label">Manager:</span>
				<span class="value">${data.manager}</span>
			</div>
			${getStrList(data.email, makeEmailTemplate)}
			${getStrList(data.phone, makePhoneTemplate)}
		`;
	};
}
