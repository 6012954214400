import $ from 'jquery';
import swal from 'sweetalert2';
import {load} from 'recaptcha-v3'
import 'jquery-mask-plugin';
import 'jquery-validation';
import config from './submit.config';
import Modal from '../modal'

const modal = new Modal();

$.validator.addMethod('customPhone', function (value, element) {
    return this.optional(element) || /^\(\d{3}\)\s\d{3}-\d{4}$/.test(value);
}, "Please enter a valid phone number");
$.validator.addMethod("customEmail", function (value, element) {
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
}, "Please enter a valid email address");
$.extend($.validator.messages, {
    required: "This field is required",
    email: "Please enter a valid email address",
});

export default class SubmitingForm {
    constructor(opts) {
        this.$phoneForms = $('[data-form="phone"]');
        this.$emailForms = $('[data-form="email"]');
        this.$loading = $('#loading');
        $.extend(this, opts);
    };

    init() {
        $('[name="phone"]').mask('(000) 000-0000', {placeholder: "(___) ___-____"});

        this.$phoneForms.each((index, item) => {
            $(item).validate({
                rules: {
                    phone: {
                        required: true,
                        customPhone: true
                    }
                },
                submitHandler: (form) => {
                    $(form).find('button[type="submit"]').attr('disabled', 'true');
                    load(config.RECAPTCHA_KEY).then((recaptcha) => {
                        recaptcha.execute('contactRequests')
                            .then((token) => {
                                // console.log(token);
                                this.sendAjaxForm($(form), {
                                        method: config.API_SEND_METHOD,
                                        url: config.BASE_URL + config.CONTACT_REQUEST,
                                        token: token
                                    }
                                )
                            });
                    });
                }
            });
        });

        this.$emailForms.each((index, item) => {
            $(item).validate({
                rules: {
                    email: {
                        required: {
                            depends: function () {
                                $(this).val($.trim($(this).val()));
                                return true;
                            }
                        },
                        customEmail: true
                    }
                },
                submitHandler: (form) => {
                    $(form).find('button[type="submit"]').attr('disabled', 'true');
                    load(config.RECAPTCHA_KEY).then((recaptcha) => {
                        recaptcha.execute('catalogDownloads')
                            .then((token) => {
                                this.sendAjaxForm($(form), {
                                        method: config.API_SEND_METHOD,
                                        url: config.BASE_URL + config.CATALOG_DOWNLOAD,
                                        token: token
                                    }
                                );
                            })
                    });
                }
            });
        });
    };

    sendAjaxForm($form, params) {
        const httpRequest = '/' + params.url.split('/')[params.url.split('/').length - 1];
        const request = (httpRequest) => {
            if (httpRequest === config.CONTACT_REQUEST) {
                return {
                    "phone_number": $form.find("input[name='phone']").val(),
                    "g-recaptcha-response": `${params.token}`
                }
            } else if (httpRequest === config.CATALOG_DOWNLOAD) {
                // console.log({
                //     "email": $form.find("input[name='email']").val(),
                //     "subscribe": $form.find("input[name='checkbox']").prop('checked'),
                // });
                return {
                    "email": $form.find("input[name='email']").val(),
                    "subscribe": $form.find("input[name='checkbox']").prop('checked'),
                    "g-recaptcha-response": `${params.token}`
                }
            }
        };

        $.ajax({
            type: params.method,
            url: `${params.url}`,
            data: JSON.stringify(request(httpRequest)),
            contentType: "application/json",
            dataType: "json",

            error: (response) => {
                // console.log(response.status);
                // console.log(JSON.parse(response.responseText).errors);
                let message = {};

                switch ($form.attr('data-form')) {
                    case 'phone':
                        message.title = 'Something wrong!';
                        message.text = 'Please re-enter your number!';
                        break;
                    case 'email':
                        message.title = 'Something wrong!';
                        message.text = 'Please enter a valid email address!';
                        break;
                    default:
                        message = '';
                }
                this.successNotification('danger', message);
            },
            success: (response) => {
                // console.log(response.message);
                let message = {};

                switch ($form.attr('data-form')) {
                    case 'phone':
                        message.title = 'Thank you for using Truck&Shop.';
                        message.text = 'We\'ll call you back soon.';
                        break;
                    case 'email':
                        message.title = 'Please check you email for download link';
                        message.text = 'If you have not received the email, please check the spam folder.';
                        break;
                    default:
                        message = '';
                }

                this.successNotification('success', message);
            },
            beforeSend: () => {
                // console.log('Handle the beforeSend event');
                this.$loading.show();
            },
            complete: () => {
                // console.log('Handle the complete event');
                this.$loading.hide();
                $form.find('.valid').val('');
                $form.find('button[type="submit"]').removeAttr('disabled');
                modal.closeModal();
            }
        })
    };

    successNotification(typeResponse, msg) {
        let notification = swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 10000,
            inputAttributes: {
                'data-aos': 'zoom-in'
            }
        });

        notification({
            customClass: `alert alert_${typeResponse}`,
            title: msg.title,
            text: msg.text
        });
    };
}