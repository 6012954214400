import $ from 'jquery';
import MapPosition from './mapPosition';
import {insertionListener} from './googleMapsMarkerLabelPositionFix'

export default (() => {
	const gridGutterWidth = 32;
	const mapPosition = new MapPosition();

	const $container = $('.container');
	const $locationNav = $('.location__navigate');
	
	const locationNavFn = ($element, $container, gridGutterWidth) => {
		function getPositionParam($element) {
			return {
				width: $element.innerWidth(),
				left: ($container.outerWidth() <= 768) ? '0' : $element.offset().left,
				right: ($container.outerWidth() <= 768) ? '12px' : 'auto'
			}
		}

        if ($container.outerWidth() <= 768) {
            $element.css('right', getPositionParam($element).right);
            $element.css('left', 'auto');
		} else {
            $element.css('left', (getPositionParam($container).left + getPositionParam($container).width) - (getPositionParam($element).width + gridGutterWidth / 2) + 'px')
            $element.css('right', 'auto');
        }
	};
	

	// _qMapsMarkerLabelPositionFix init
	document.addEventListener('animationstart', insertionListener);


	$(document).ready(() => {
        mapPosition.init();
		locationNavFn($locationNav, $container, gridGutterWidth);
    });
	$(window).on('resize', () => {
		locationNavFn($locationNav, $container, gridGutterWidth);
	});
})();