import $ from "jquery";

export function insertionListener(e) {
	const $target = $(e.target);

	if (e.animationName === "nodeInserted" && $target.text()) {
		const style = $target.attr('style') || '';

		if (style.indexOf('Poppins') >= 0) {
			$target.parent().parent().addClass('customMarkerLabel');
            $("#map").stop(true, true).delay(500).animate({opacity: 1}, 50);
		}
	}
}
