import $ from 'jquery';
import anime from 'animejs';

export default class Animation {
    howBuy() {
        let svgBgIcon = anime.timeline();
        let indexDefault = 0;
        let listBuyStepsItemsConfig = [];
        let listBuyStepsItems = $('#list-buy-steps .list-buy-steps__item');
        
        let howBuyConfig = {
            lineDraw: {
                strokeDashoffset: [anime.setDashoffset, 10],
                easing: 'linear',
                duration: 400,
                opacity: 1,
                delay: function (el, i) {
                    return i * 100
                },
            },
            svgIcon: {
                targets: '#svg_bg-icon path',
                fill: "url(#paint0_linear)",
                easing: 'linear',
                delay: 100,
                opacity: 1,
                direction: 'alternate',
                duration: function (el, i, l) {
                    return i * 40;
                }
            }
        };

        listBuyStepsItems.each((i, element) => {
            let id = element.parentNode.id;
            let modClass = element.className.split(' ')[1];
            let targets = `#${id} .${modClass}`;
            const itemConfig = {
                targets: targets,
                translateY: -50,
                duration: 400,
                offset: 0,
                delay: 0,
                easing: 'linear',
                opacity: 1
            };

            listBuyStepsItemsConfig.push(itemConfig);
        });

        listBuyStepsItemsConfig[indexDefault].delay = 500;

        anime(listBuyStepsItemsConfig[indexDefault]).finished
            .then((nextIndex = indexDefault) => {
                if ($('#svg_line_01 path').length) {
                    howBuyConfig.lineDraw.targets = '#svg_line_01 path';
                    howBuyConfig.lineDraw.direction = 'normal';
                }

                svgBgIcon.add(howBuyConfig.svgIcon);

                return anime(howBuyConfig.lineDraw).finished.then(() => nextIndex += 1);
            })
            .then((nextIndex) => {

                anime(listBuyStepsItemsConfig[nextIndex]).finished.then(() => {
                        if ($('#svg_line_02 path').length) {
                            howBuyConfig.lineDraw.targets = '#svg_line_02 path';
                            howBuyConfig.lineDraw.direction = 'normal';
                        }
                        return anime(howBuyConfig.lineDraw).finished.then(() => nextIndex += 1);
                    })
                    .then((nextIndex) => {

                        anime(listBuyStepsItemsConfig[nextIndex]).finished.then(() => {})
                    });
            });
    }
}