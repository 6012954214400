const imgPath = 'images/content/catalog/';

export default {
    catalogListFilter: [
        {
            text: 'All',
            category: 'all',
            selected: true
        },
        {
            text: 'Exterior',
            category: 'exterior',
        },
        {
            text: 'Lighting',
            category: 'lighting',
        },
        {
            text: 'Performance Parts',
            category: 'performance_parts',
        },
        {
            text: 'Trailer Parts',
            category: 'trailer_parts',
        },
        {
            text: 'Wheels and Tires',
            category: 'wheels_tires',
        },
        {
            text: 'Others',
            category: 'other',
        }
    ],
    catalogList: [
        {
            category: 'exterior',
            title: 'Bumpers',
            img: {
                src: `${imgPath}category/Exterior/Bamper/Bamper.png`,
                alt: 'Bamper'
            }
        },
        {
            category: 'exterior',
            title: 'Fairings',
            img: {
                src: `${imgPath}category/Exterior/FairRing/FairRing.png`,
                alt: 'FairRing'
            }
        },
        {
            category: 'exterior',
            title: 'Fairing Steps',
            img: {
                src: `${imgPath}category/Exterior/Fairing Steps/FairingSteps.png`,
                alt: 'FairingSteps'
            }
        },
        {
            category: 'exterior',
            title: 'Cab Extenders',
            img: {
                src: `${imgPath}category/Exterior/Cab Extenders/CabExtenders.png`,
                alt: 'FairingSteps'
            }
        },
        {
            category: 'exterior',
            title: 'Mirrors',
            img: {
                src: `${imgPath}category/Exterior/Mirror/Mirror.png`,
                alt: 'FairingSteps'
            }
        },
        {
            category: 'exterior',
            title: 'Grilles',
            img: {
                src: `${imgPath}category/Exterior/Grils/Grill.png`,
                alt: 'Grill'
            }
        },
        {
            category: 'exterior',
            title: 'Hoods',
            img: {
                src: `${imgPath}category/Exterior/Hoods/Hoods.png`,
                alt: 'Hoods'
            }
        },
        {
            category: 'exterior',
            title: 'Brackets',
            img: {
                src: `${imgPath}category/Exterior/Brackets and Hinge/Brackets-and-Hinge.png`,
                alt: 'Brackets'
            }
        },
        {
            category: 'exterior',
            title: 'Chrome Parts',
            img: {
                src: `${imgPath}category/Exterior/Chrome Parts/Chrome Parts.png`,
                alt: 'Brackets'
            }
        },
        {
            category: 'exterior',
            title: 'Deer Guards',
            img: {
                src: `${imgPath}category/Exterior/Deer Guards/Deer Guards.png`,
                alt: 'Deer Guards'
            }
        },
        {
            category: 'exterior',
            title: 'Fenders',
            img: {
                src: `${imgPath}category/Exterior/Fender/Fender.png`,
                alt: 'Fender'
            }
        },
        // lighting
        {
            category: 'lighting',
            title: 'Headlights',
            img: {
                src: `${imgPath}category/Light/Headlight/HeadLight.png`,
                alt: 'HeadLight'
            }
        },
        {
            category: 'lighting',
            title: 'Fog Lights',
            img: {
                src: `${imgPath}category/Light/Fog Light/FOG-LIGHTS.png`,
                alt: 'Fog Light'
            }
        },
        {
            category: 'lighting',
            title: 'Lamps',
            img: {
                src: `${imgPath}category/Light/Lamp/Lamp.png`,
                alt: 'Lamp'
            }
        },
        // performance_parts
        {
            category: 'performance_parts',
            title: 'Air Filters',
            img: {
                src: `${imgPath}category/Performance Parts/Air Filters/Air-Filters.png`,
                alt: 'Air Filters'
            }
        },
        {
            category: 'performance_parts',
            title: 'Condensers',
            img: {
                src: `${imgPath}category/Performance Parts/Condensers/Condensers.png`,
                alt: 'Condensers'
            }
        },
        {
            category: 'performance_parts',
            title: 'Oil Filters',
            img: {
                src: `${imgPath}category/Performance Parts/Oil Filter/Oil-Filters.png`,
                alt: 'Oil Filter'
            }
        },
        {
            category: 'performance_parts',
            title: 'Tanks',
            img: {
                src: `${imgPath}category/Performance Parts/Tanks/Tanks.png`,
                alt: 'Tanks'
            }
        },
        {
            category: 'performance_parts',
            title: 'Fuel Filters',
            img: {
                src: `${imgPath}category/Performance Parts/Fuel Filters/Fuel Filters.png`,
                alt: 'Fuel Filters'
            }
        },
        {
            category: 'performance_parts',
            title: 'Fan Covers',
            img: {
                src: `${imgPath}category/Performance Parts/Fan Covers/Fan Covers.png`,
                alt: 'Fuel Filters'
            }
        },
        {
            category: 'performance_parts',
            title: 'Shock Absorbers',
            img: {
                src: `${imgPath}category/Performance Parts/Shock Absorbers/Shock-Absorbers.png`,
                alt: 'Shock Absorbers'
            }
        },
        {
            category: 'performance_parts',
            title: 'Air Springs',
            img: {
                src: `${imgPath}category/Performance Parts/Air Springs/Air-Springs.png`,
                alt: 'Air Springs'
            }
        },
        {
            category: 'performance_parts',
            title: 'Brake Systems',
            img: {
                src: `${imgPath}category/Performance Parts/Brake Systems/Brake-Systems.png`,
                alt: 'Brake Systems'
            }
        },
        {
            category: 'performance_parts',
            title: 'Water Filters',
            img: {
                src: `${imgPath}category/Performance Parts/Water Filter/Water-Filters.png`,
                alt: 'Water Filters'
            }
        },
        // trailer_parts
        {
            category: 'trailer_parts',
            title: 'Hoses',
            img: {
                src: `${imgPath}category/Trailer Parts/Hoses/Hoses.png`,
                alt: 'Hoses'
            }
        },
        {
            category: 'trailer_parts',
            title: 'Straps',
            img: {
                src: `${imgPath}category/Trailer Parts/Straps/Straps.png`,
                alt: 'Straps'
            }
        },
        // wheels_tires
        {
            category: 'wheels_tires',
            title: 'Hub Caps',
            img: {
                src: `${imgPath}category/Wheels and Tires/Hub Caps/Hub-Caps.png`,
                alt: 'Hub Caps'
            }
        },
        {
            category: 'wheels_tires',
            title: 'Wheels',
            img: {
                src: `${imgPath}category/Wheels and Tires/Wheels/Wheels_02.png`,
                alt: 'Wheels'
            }
        },
        {
            category: 'wheels_tires',
            title: 'Tires',
            img: {
                src: `${imgPath}category/Wheels and Tires/Tires/Tires.png`,
                alt: 'Tires'
            }
        },

        // other
        {
            category: 'other',
            title: 'Switches',
            img: {
                src: `${imgPath}category/Other/Switches/Switches.png`,
                alt: 'Switches'
            }
        },
        {
            category: 'other',
            title: 'Sun Visors',
            img: {
                src: `${imgPath}category/Other/Sun Visors/Sun Visors.png`,
                alt: 'Sun Visors'
            }
        },
        {
            category: 'other',
            title: 'Exhaust Pipes',
            img: {
                src: `${imgPath}category/Other/Exhaust Pipes/Exhaust-Pipes.png`,
                alt: 'Exhaust Pipes'
            }
        },
        {
            category: 'other',
            title: 'Termoking Doors',
            img: {
                src: `${imgPath}category/Other/Termoking Doors/Termoking Doors.png`,
                alt: 'Termoking Doors'
            }
        },
        {
            category: 'other',
            title: 'Rubbers',
            img: {
                src: `${imgPath}category/Other/Rubber/Rubber.png`,
                alt: 'Rubbers'
            }
        },
        {
            category: 'other',
            title: 'Door Handles',
            img: {
                src: `${imgPath}category/Other/Door Handle/Door-Handle.png`,
                alt: 'Door Handles'
            }
        },
    ],
    catalogSwiper: [
        {
            title: 'Freightliner Cascadia Bumper',
            price: '$575.00',
            img: {
                src: `${imgPath}products/Freughtliner Cascadia Bumper/Freughtliner Cascadia Bumper.jpg`,
                alt: 'Freightliner Cascadia Bumper'
            }
        },
        {
            title: 'Freightliner Cascadia Grille With Bug Screen',
            price: '$250.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Grille With Bug Screen/Freightliner Cascadia Grille With Bug Screen.jpg`,
                alt: 'Freightliner Cascadia Grille With Bug Screen'
            }
        },
        {
            title: 'Freightliner Cascadia Fog Light',
            price: '$30.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Fog Light/Freightliner Cascadia Fog Light.jpg`,
                alt: 'Freightliner Cascadia Fog Light'
            }
        },
        {
            title: 'Freightliner Cascadia Bumper Bracket',
            price: '$25.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Bumper Bracket/Freightliner Cascadia Bumper Bracket.jpg`,
                alt: 'Freightliner Cascadia Bumper Bracket'
            }
        },
        {
            title: 'Freightliner Cascadia Headlight',
            price: '$95.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Headlight/Freightliner Cascadia Headlight.jpg`,
                alt: 'Freightliner Cascadia Headlight'
            }
        },
        {
            title: 'Freightliner Cascadia Reinforcement',
            price: '$120.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Reinforcement/Freightliner Cascadia Reinforcement.jpg`,
                alt: 'Freightliner Cascadia Reinforcement'
            }
        },
        {
            title: 'Freightliner Cascadia Cover',
            price: '$90.00',
            img: {
                src: `${imgPath}products/Freightliner Cascadia Cover/Freightliner Cascadia Cover.jpg`,
                alt: 'Freightliner Cascadia Cover'
            }
        },
        {
            title: 'Volvo VNL Headlight',
            price: '$185.00',
            img: {
                src: `${imgPath}products/Volvo VNL Headlight/Volvo VNL Headlight.jpg`,
                alt: 'Volvo VNL Headlight'
            }
        },
        {
            title: 'Volvo VNL Side Fairing Front',
            price: '$310.00',
            img: {
                src: `${imgPath}/products/Volvo VNL Side Fairing Front/Volvo VNL Side Fairing Front.jpg`,
                alt: 'Volvo VNL Side Fairing Front'
            }
        },
    ]
}