import 'svg4everybody';
import $ from 'jquery';

import '../js/helpers/helpers';
import {breakpoints} from './helpers/breakpoints';
import './modules/google-tag-manager';
import {isMobile} from "./helpers/detect-devise";

import Preloader from './modules/preloader/';
import Navigation from './modules/navigate/';
import Animation from './modules/animation/';
import Modal from './modules/modal';
import './modules/accordion/';
import './modules/catalog/';
import './modules/location/';
import './modules/swiper/';
import './modules/submiting-form';

window.$ = window.jQuery = $;

const preloader = new Preloader('#loader');
const navigate = new Navigation();
const animation = new Animation();
const modal = new Modal();

let iphoneGold = $('.svg-parallax_02 .svg-parallax__img');

/* ==============
====================      LOAD      =====================
======================================================== */
window.addEventListener('load', () => {
    preloader.init();
    modal.init();
    if ($('.how-buy').offsetCenter()) {
        animation.howBuy();
    } else {
        window.addEventListener('scroll', customScroll);
    }
});

/*==============
====================      READY      =====================
========================================================*/
$(document).ready(() => {
    if( isMobile.any() ) {
        $('body').addClass(`is-mobile is-${isMobile.any()[0]}`);
    }
    if (process.env.NODE_ENV !== 'production') {
        // $('body *').addClass(`development-mode`);
    }
    navigate.init();
    responsiveBreakpoints(breakpoints);
});

/*==============
====================      SCROLL      ====================
========================================================*/
$(window).scroll(() => {
    navigate.fillNavigate();
});

/*==============
====================      RESIZE      ====================
========================================================*/
$(window).resize(() => {
    responsiveBreakpoints(breakpoints);
    navigate.resize();
});

/*==============
====================      functions      =====================
========================================================*/
function customScroll() {
    if ($('.how-buy').offsetCenter()) {
        window.removeEventListener('scroll', customScroll);
        animation.howBuy();
    }
}

function responsiveBreakpoints(breakpoints) {
    const $wndScnWidth = $(window).outerWidth();
    const $containerWidth = $('.container').outerWidth();
    const $phoneFormWidth = $('.svg-parallax.svg-parallax_02 .form_phone-send').outerWidth();

    if ($wndScnWidth >= breakpoints.xl) {
        $(iphoneGold).css('right', () => (($wndScnWidth - $containerWidth) / 2) + $phoneFormWidth + 'px');
    } else if ($wndScnWidth >= breakpoints.lg) {
        $(iphoneGold).css('right', () => (($wndScnWidth - $containerWidth) / 2) + $phoneFormWidth - 80 + 'px');
    }
}

