import $ from 'jquery';
import CatalogFilter from './catalogFilter';

export default (() => {
    const catalogFilter = new CatalogFilter({
        visibleCardsCount: 6
    });

    $(document).ready(() => {
        catalogFilter.init();
    });
})();