import anime from 'animejs';
import catallogConfig from './catalog.config';
import $ from 'jquery';

export default class CatalogFilter {
	constructor(opts) {
        $.extend(this, opts); // Object.assign
        this.catalogList = catallogConfig.catalogList;
		this.$filterEl = $('#catalog-filter');
		this.$resultEL = $('#filter-result');
		this._category = 'all';
		this._text = 'All';
		this._hiddenElements = this.catalogList.slice(this.visibleCardsCount);
        this.$catalogFilterCheck = $('.catalog-filter-check');
	};

	init() {
		this.renderCards(this.catalogList);
		this.$filterEl.click(e => this.onCategoryChange(e));
        this.$catalogFilterCheck.click(e => this.mobileFilterSelect(e));
	};

	getCategory(e) {
		const $el = $(e.target).closest('.catalog-filter-list__item');
		const $filterItems = this.$filterEl.find('.catalog-filter-list__item');

		$filterItems.each((i, item) => $(item).removeClass('selected'));
		$el.addClass('selected');

		return {
			attr: $el.attr('data-category'),
			text: $el.text()
		};
	};

	renderCards(catalogData, loadMore) {
		const catalogCardTemplate = [];

		if (!loadMore) {
			catalogData.forEach((item, index) => {
				if (index < this.visibleCardsCount) {
					catalogCardTemplate.push(this.makeCard(item));
				}
			});

			if (catalogData.length > this.visibleCardsCount) {
				catalogCardTemplate.push(this.makeButton());
			}

		} else {
			catalogData.forEach((item) => catalogCardTemplate.push(this.makeCard(item, loadMore)));
		}

		const template = catalogCardTemplate.toString().replace(/,/g, '');

		this.$resultEL
			.append(template)
			.find('.js-load-more')
			.click(e => this.showMoreBtnClick(e));

		this.animate(`#filter-result .filter-result__item${loadMore ? '_hidden' : ''}`);
	};

	onCategoryChange(e) {
		const clickResult = this.getCategory(e);

		if (!clickResult || clickResult === this._category) return;

		this._category = clickResult.attr;
		this._text = clickResult.text;
        this.$resultEL.children().remove();

		const categoryToShow = this._category === 'all'
			? this.catalogList
			: this.catalogList.filter((item) => item.category === this._category);

		this._hiddenElements = categoryToShow.slice(this.visibleCardsCount);
		this.renderCards(categoryToShow);

		// mobile view
        this.$catalogFilterCheck.children().html(this._text);
        this.$catalogFilterCheck.attr('data-open', 'false');
        this.$catalogFilterCheck.next().slideUp();
	};

	mobileFilterSelect(e) {
        e.preventDefault();
        e.stopPropagation();
        const $target = $(e.target);
        const $this = $target.closest('.catalog-filter-check');

        if (!$this && $this === null) return;

        const $catalogFilter = $this.next();

        if ($catalogFilter.is(":visible")) {
            $catalogFilter.slideUp();
            $this.attr('data-open', 'false');
        } else {
            $catalogFilter.slideDown();
            $this.attr('data-open', 'true');
        }
	}

	showMoreBtnClick(e) {
        const $target = $(e.target);
        const $btn = $target.closest('.btn[type="button"]');

        if ($btn.length) {
            $btn.parent().remove();
            this.renderCards(this._hiddenElements, 'loadMore');
        }
	}

	animate(selector) {
		anime({
			targets: selector,
			duration: function (t, i) {
				return 1000 + i * 250;
			},
			easing: 'easeOutExpo',
			delay: function (t, i) {
				return i * 20;
			},
			opacity: {
				value: [0, 1],
				duration: function (t, i) {
					return 250 + i * 50;
				},
				easing: 'linear'
			},
			translateY: [200, 0]
		});
	}

	makeCard(item, hidden) {
		return `
			<div class="filter-result__item ${hidden ? 'filter-result__item_hidden' : ''} col-6 col-md-4" data-result="${item.category}">
				<div class="categories-card">
					<div class="categories-card__img-zone">
						<img src="${(item.img) ? item.img.src : '/images/content/catalog/catalog IMG Default.jpg'}" 
							 alt="${(item.img) ? item.img.alt : ''}"/>
					</div>
					<div class="categories-card__title">${item.title}</div>
					<div class="categories-card__btn-wrap">
						<button class="btn categories-card__btn" type="submit" data-open="modal">Buy Now</button>
					</div>
				</div>
			</div>`
	}

	makeButton() {
		return `
			<div class="filter-result__item filter-result__item_has-btn col-12 js-load-more">	
				<button class="btn filter-result__btn" type="button">Show More</button>
			</div>`
	};
}
