export default {
    BASE_URL: (process.env.NODE_ENV === 'production')
        ? 'https://76nvnbxol0.execute-api.us-east-1.amazonaws.com/prod'
        : 'https://ryeo5r630j.execute-api.eu-west-1.amazonaws.com/dev',
    CONTACT_REQUEST: '/contact-requests',
    CATALOG_DOWNLOAD: '/catalog-downloads',
    RECAPTCHA_KEY: (process.env.NODE_ENV === 'production')
        ? '6LeNE30UAAAAAPxl-vjSf4BjAu6km58JnwNdc3Ch'
        : '6Lf-AX0UAAAAAIW11LwDzmJR3Q-p14cGzs4nvTXb',
    API_SEND_METHOD: 'POST'
}