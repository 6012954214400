import Navigation from '../../modules/navigate';
import AOS from 'aos';

const navigate = new Navigation();

export default class Preloader {
    constructor(loader) {
        this.$loader = $(loader);
    }

    init() {
        function noscroll() {
            window.scrollTo( 0, 0 );
        }
        window.addEventListener('scroll', noscroll);
        this.$loader.find('.loader__svg').append('<img src="/images/content/logo/logo.gif" alt="">');

        this.$loader.find('.loader__overlay').stop().animate({opacity: 1}, 1000, () => {
            navigate.fillNavigate();
            window.removeEventListener('scroll', noscroll);
        });
        this.$loader.delay(500).animate({opacity: 0}, 1000, () => {
            this.$loader.remove();
            AOS.init();
        });
    }
}