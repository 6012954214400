import $ from 'jquery';
import config from './location.config';
import GoogleMapsLoader from 'google-maps';
export default class Map {
    constructor(mapId) {
        this.map = $(`#${mapId}`)[0];
        this.config = config.gMapsConfig
    }

    init(position, markerDesc) {
        GoogleMapsLoader.KEY = this.config.KEY;
        GoogleMapsLoader.VERSION = this.config.VERSION;

        GoogleMapsLoader.load( (google) => {

            const map = new google.maps.Map(this.map, {
                center: position,
                zoom: this.config.zoom,
                styles: this.config.snazyStyles,
                disableDefaultUI: true,
            });
            
            new google.maps.Marker({
                position: position, 
                map: map,
                icon: this.config.marker.icon,
                title: markerDesc,
                label: {
                    text: markerDesc,
                    color: this.config.marker.label.color,
                    fontFamily: this.config.marker.label.fontFamily,
                    fontSize: this.config.marker.label.fontSize,
                    fontWeight: this.config.marker.label.fontWeight,
                },
            })
        });
    }
}