import $ from 'jquery';
import { breakpoints } from '../../helpers/breakpoints';

export default class Navigation {
    constructor(opts) {
        const assideNav = $('#asside-nav');
        const header = $('.header');
        const footer = $('.footer');
        const navigateList = [];

        this.$asside = assideNav;
        this.$header = header;
        this.$brandList = $('.brand');
        this.$navListHeader = header.find('.nav-list');
        this.$navListFooter = footer.find('.nav-list');
        this.$hamburger = $('.hamburger');
        this.$navListHeader.find('a').each((i, item) => {
            navigateList.push($(item).attr('data-anchor'));
        });
        this.bgToHEX = '#FFFFFF';
        this.navigateList = navigateList;

        $.extend(this, opts); // Object.assign
    };

    init() {
        this.$navListHeader.click(e => this.navigateHeader(e));
        this.$navListFooter.click(e => this.navigateFooter(e));
        this.$brandList.each((i, item) => {
            $(item).click(e => this.toTop(e));
        });

        this.mobileView(breakpoints);
        this.$asside.find('[data-anchor]').on('click', e => {
            $(e.target)
                .closest('.asside-nav')
                .find('.hamburger').click();
        });
        this.$hamburger.on('click', (e) => {
            this.mobileNavigateToogle(e);
        });
    };

    resize() {
        this.mobileView(breakpoints);
    }

    toTop(e) {
        e.preventDefault();
        e.stopPropagation();

        $('html, body').animate({
            scrollTop: 0
        }, 2000);
    };

    navigateHeader(e) {
        e.preventDefault();
        e.stopPropagation();

        const $target = $(e.target);
        const $targetLink = $target.closest('a');

        if ($targetLink.length) {
            const sectionId = $targetLink.attr('href');
            const $navLinkLists = $target.closest('ul').find('a');

            if ($targetLink.hasClass('is-active')) return;

            if ($(window).outerWidth() < breakpoints.md) {
                $('html, body').animate({
                    scrollTop: $(sectionId).offset().top - 32
                }, 2000);
            } else {
                $('html, body').animate({
                    scrollTop: $(sectionId).offset().top
                }, 2000);
            }

            $navLinkLists.removeClass('is-active');
            $targetLink.addClass('is-active');
        }
    };

    navigateFooter(e) {
        e.preventDefault();
        e.stopPropagation();

        const $target = $(e.target);
        const $fakeTargetLink = $target.closest('[data-href]');

        if ($fakeTargetLink.length) {
            const $navLinkLists = this.$navListHeader.find('a');

            $navLinkLists.each((i, item) => {
                const $item = $(item);

                if ($item.attr('data-anchor') === $fakeTargetLink.attr('data-href').replace(/#/g, '')) {
                    $item.click();
                }
            });
        }
    };

    fillNavigate(bgHEX = this.bgToHEX, fixedSelector = 'fixed') {
        const scrollTop = $(window).scrollTop();
        const wHeight = $(window).outerHeight();
        const opacityFill = ((scrollTop > fixedMenu(wHeight, 15)) || (wHeight < 600)) ? 1 : scrollTop * 2 / 1000;
        const hexToStr = $.map($.hexToRgb(bgHEX), (val) => val).toString();

        this.$header.css('background', `rgba(${hexToStr},${opacityFill})`);

        if ((scrollTop > fixedMenu(wHeight, 15)) || (wHeight < 600)) {
            this.$header.addClass(fixedSelector)
        } else {
            this.$header.removeClass(fixedSelector);
        }

        if (this.navigateList.length > 0) {
            $(this.navigateList).each((i, item) => {
                const topLine = Math.round($(`#${item}`).offset().top);
                const sectionHeight = Math.round($(`#${item}`).innerHeight());

                if ($(window).outerWidth() < breakpoints.md) {
                    if (scrollTop >= (topLine - 32) && scrollTop < (topLine - 32 + sectionHeight - 1)) {
                        $('[data-anchor="' + item + '"]').addClass('is-active');
                    } else {
                        $('[data-anchor="' + item + '"]').removeClass('is-active');
                    }
                } else {
                    if (scrollTop >= topLine && scrollTop < (topLine + sectionHeight - 1)) {
                        $('[data-anchor="' + item + '"]').addClass('is-active');
                    } else {
                        $('[data-anchor="' + item + '"]').removeClass('is-active');
                    }
                }

            });
        }
        function fixedMenu(wh, persent) {
            return Math.round((wh * persent) / 100);
        }
    };

    mobileNavigateToogle(e) {
        const $target = $(e.target);
        const $hamburger = $target.closest('.hamburger');
        const $headerConteiner = this.$header.find('.header__container');

        if ($hamburger.length) {
            $hamburger.toggleClass('is-open');
            this.$asside.toggleClass('is-open');

            if (!this.$asside.has('.hamburger').length) {
                this.$asside.prepend($hamburger);
            } else {
                $headerConteiner.prepend($hamburger);
            }
        }
    };

    mobileView(breakpoints) {
        const $wndScnWidth = $(window).outerWidth();
        const $headerNavConteiner = this.$header.find('.header__nav-container');

        if ($wndScnWidth < breakpoints.lg) {
            this.$header.closest('#page').addClass('mobileView');
            this.$header.addClass('header_mobileView mobileView__header');

            if (!this.$asside.has('ul.nav-list').length) {
                this.$asside.prepend(this.$navListHeader);
            } else return;
        } else {
            this.$header.closest('#page').addClass('mobileView');
            this.$header.removeClass('header_mobileView mobileView__header');

            if (!$headerNavConteiner.has('ul.nav-list').length) {
                $headerNavConteiner.prepend(this.$navListHeader);
            } else return;
        }
    }
}
