import Swiper from 'swiper';
import swiperConfig from './swiper.config';
import catalogConfig from '../catalog/catalog.config';
import {isMobile} from "../../helpers/detect-devise";

function makeCatalogCard(item) {
    return `
        <div class="swiper-slide swiperCatalog">
            <div class="swiperCatalog__img-zone">
                <img src="${(item.img) ? item.img.src : '/images/content/catalog/catalog IMG Default.jpg'}" 
                     alt="${(item.img) ? item.img.alt : ''}"/>
            </div>
            <div class="swiperCatalog__title">${item.title}</div>
            <div class="swiperCatalog__price">${item.price}</div>
            <div class="swiperCatalog__btn-wraper">
                <button class="btn swiperCatalog__btn" type="submit" data-open="modal">Buy Now</button>
            </div>
        </div>`
}

function makeBuyNowCard(item) {
    return `
        <div class="swiper-slide swiperBuyNow">
            <div class="swiperBuyNow__img">
                <img src="${(item.img) ? item.img.src : '/images/content/catalog/catalog IMG Default.jpg'}" 
                         alt="${(item.img) ? item.img.alt : ''}"/>
            </div>
        </div>`
}

function renderCatalogCards(catalogData) {
    const catalogCard = [];

    if (catalogData.length) {
        catalogData.forEach(item => {
            catalogCard.push(makeCatalogCard(item));
        });
    }

    return catalogCard;
}
function renderBuyNowCards(catalogData) {
    const catalogCard = [];

    if (catalogData.length) {
        catalogData.forEach(item => {
            catalogCard.push(makeBuyNowCard(item));
        });
    }

    return catalogCard;
}

export default (() => {
    const swiperWorks = new Swiper('#swiperWorks', swiperConfig.swiperWorks);
    const swiperCatalog = new Swiper('#swiperCatalog', swiperConfig.swiperCatalog);
    const swiperReviews = new Swiper('#swiperReviews', swiperConfig.swiperReviews);
    const swiperBuyNow = new Swiper('#swiperBuyNow', swiperConfig.swiperBuyNow);

    document.addEventListener('DOMContentLoaded', () => {
        swiperWorks.init();

        swiperCatalog.init();
        swiperCatalog.update();
        setTimeout(() => {
            swiperCatalog.addSlide(1, renderCatalogCards(catalogConfig.catalogSwiper));
        },10);

        swiperReviews.init();

        swiperBuyNow.init();
        setTimeout(() => {
            swiperBuyNow.appendSlide(renderBuyNowCards(catalogConfig.catalogSwiper));
        },10);
    });
})();