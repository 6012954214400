import $ from 'jquery';

export default class Accordion {
    constructor(accordion) {
        this.accordion = $(accordion);
        this.accordionItemList = this.accordion.children();
        this.accordionCntList = this.accordion.find('.accordion__cnt');
    }

    init() {
        this.accordion.on('click', (e)=> {
            e.preventDefault();
            e.stopPropagation();

            const $target = $(e.target);
            const $accordionHead = $target.closest('.accordion__head');

            if (!$accordionHead.length) return;

            const $accordionItem = $target.closest('.accordion__item');
            const $accordionContent = $accordionItem.find('.accordion__cnt');

            $(this.accordionItemList).removeClass('accordion__item_opened');
            $(this.accordionCntList).slideUp();

            if (!$accordionContent.is(":visible")) {
                $accordionContent.slideDown();
                $accordionItem.addClass('accordion__item_opened');
            }
        });
    }
}
