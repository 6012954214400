var interleaveOffset = 0.5;

export default {
    swiperWorks: {
        init: false,
        loop: true,
        centeredSlides: true,
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        spaceBetween: 32,
        slideActiveClass: 'swiperWorks__active',
        pagination: {
            el: '.swiperWorks__pagination',
            bulletClass: 'swiperWorks__pagination-bullet',
            bulletActiveClass: 'swiperWorks__pagination-bullet--active',
            clickable: true,
            renderBullet: function (index, className) {
                return `<span class="${className}" style="background-image: url('${this.imagesToLoad[index].getAttribute('src')}')"></span>`;
            },
        },
        navigation: {
            nextEl: '.swiperWorks__nav-btn--next',
            prevEl: '.swiperWorks__nav-btn--prev',
        },
        breakpoints: {
            1023: {
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: 0,
            }
        }
    },
    swiperCatalog: {
        init: false,
        loop: true,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 32,
        navigation: {
            nextEl: '.swiperCatalog__nav-btn--next',
            prevEl: '.swiperCatalog__nav-btn--prev',
        },
        // scrollbar: {
        //     el: '.swiperCatalog__scrollbar',
        //     hide: false,
        //     snapOnRelease:true,
        //     draggable:true
        // },
    },
    swiperReviews: {
        init: false,
        slidesPerView: 1,
        spaceBetween: 32,
        loop: true,
        navigation: {
            nextEl: '.swiperReviews__nav-btn--next',
            prevEl: '.swiperReviews__nav-btn--prev',
        },
        pagination: {
            el: '.swiperReviews__pagination',
            bulletClass: 'swiperReviews__pagination-bullet',
            bulletActiveClass: 'swiperReviews__pagination-bullet--active',
            clickable: true,
            renderBullet: function (index, className) {
                return `
                    <span class="${className}" >
                        <span>
                            <img src='${this.imagesToLoad[index + 1].getAttribute("src")}' alt=''/>
                        </span>
                    </span>
                `;
            },
        },
        breakpoints: {
            767: {
                autoplay: {
                    delay: 15000
                },
            }
        },

    },
    swiperBuyNow: {
        init: false,
        slidesPerView: 'auto',
        freeMode: true,
        freeModeSticky: true,
        spaceBetween: 27,
        autoplay: {
            delay: 15000,
            reverseDirection: true
        },
        loop: true,
        simulateTouch: false,
        allowTouchMove: false,
        breakpoints: {
            1365: {
                spaceBetween: 15,
            },
            1023: {
                spaceBetween: 11,
            },
        }
    }
}
